import React, { useContext, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase/config";
import { useAdmin } from "./hooks/useAdmin";
import Create from "./pages/create/Create";
import Login from "./pages/login/Login";
import Vtec from "./pages/vtec/Vtec";
import Admin from "./pages/admin/Admin";
import PartnerLogin from "./pages/partner/PartnerLogin";
import Composable from "./pages/composable/Composable";
import FactoryDirect from "./pages/factorydirect/FactoryDirect";
import CommonDataModel from "./pages/commondatamodel/CommonDataModel";
import PartnerAdmin from "./pages/partneradmin/PartnerAdmin";
import { useApp } from "./hooks/useApp";
import { useDocs } from "./hooks/useDocs";
import { ThemeProvider } from "@mui/material";
import Assets from "./pages/assets/Assets";
import FactoryCreate from "./pages/factoryCreate/FactoryCreate";
import createCustomTheme from "./components/theme/theme";
import PrivateAccounts from "./pages/privateAccounts/PrivateAccounts";
import Toast from "./components/Common/Toast";
import Flow from "./pages/flows/Flow";
import Uns from "./pages/uns/Uns";

function App() {
  const { authIsReady, user } = useAuth();
  const { myAdminRecord } = useAdmin();
  const {
    setSelectedOpp,
    fakeOpportunity,
    selectedOpp,
    setVtecAssets,
    combinedAssets,
    playlist,
    partnerTheme,
    setPartnerTheme
  } = useApp();
  const { getUserPrivateId } = useDocs("opportunities");

  // useEffect to re-render when 'user' changes
  useEffect(() => {
    console.log("User has changed:", user);
    if (user) {
      httpsCallable(functions, "identify")().then((result) => {
        console.log(result);
      });
      if (user.privateId) {
        setSelectedOpp({ ...fakeOpportunity, id: user.privateId });
        const currentTheme = localStorage.getItem("theme");
        if (!currentTheme) {
          async function getUserP() {
            let res = await getUserPrivateId(user.privateId);
            if (res?.theme) {
              setPartnerTheme(res.theme);
              localStorage.setItem("theme",JSON.stringify(res.theme));
            }
          }
          getUserP();
        }
        
      }
    }
    // Perform any additional logic you need on user change
  }, [user]);

  useEffect(() => {
    if (user && combinedAssets) {
      if (user.privateId) {
        if (playlist) {
          setVtecAssets(
            combinedAssets.filter(
              (asset) =>
                asset.Global ||
                user.privateId === asset.private ||
                (playlist && playlist.includes(asset.id))
            )
          );
        } else {
          setVtecAssets(
            combinedAssets.filter(
              (asset) => !asset.private || user.privateId === asset.private
            )
          );
        }
      } else {
        setVtecAssets(combinedAssets.filter((asset) => !asset.private));
      }
    }
  }, [user, combinedAssets]);

  let theme = createCustomTheme(partnerTheme.primaryColor, partnerTheme.bgColor, partnerTheme.highlight);
  useEffect(() => {
    theme = createCustomTheme(partnerTheme.primaryColor, partnerTheme.bgColor, partnerTheme.highlight);
    const root = document.documentElement;
    root?.style.setProperty(
      "--gradient-default-nav",
      `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--gradient-default",
      `linear-gradient(to left,${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--gradient-default-bottom",
      `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--radial-gradient",
      `radial-gradient(circle at 50% 50%, ${theme.palette.secondary.main} 0%, transparent 90% ), ${theme.palette.primary.main}`
    );
    root?.style.setProperty(
      "--text-white",
      `${theme.palette.customColors.white}`
    );
  }, [partnerTheme]);
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: "background.default",
          minHeight: "100vh"
        }}
      >
        {authIsReady && (
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  user && user.approved ? (
                    <Vtec />
                  ) : user ? (
                    <Navigate to="/login" replace={true} />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/login"
                element={
                  !user || (user && !user.approved) ? (
                    <Login />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              {/* <Route
                exact
                path="/assets"
                element={
                  myAdminRecord && myAdminRecord.admin ? (
                    <Assets />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route> */}
              <Route
                exact
                path="/assets"
                element={
                  user ? <Assets /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route
                exact
                path="/admin"
                element={
                  myAdminRecord && myAdminRecord.admin ? (
                    <Admin />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/partneradmin"
                element={
                  myAdminRecord && myAdminRecord.admin ? (
                    <PartnerAdmin />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/partnerlogin"
                element={
                  !user ? <PartnerLogin /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route path="/composable" element={<Composable />}></Route>
              <Route path="/composable/:param" element={<Composable />}></Route>
              <Route
                path="/factory/:account/:asset"
                element={<FactoryDirect />}
              ></Route>
              <Route
                exact
                path="/commondatamodel"
                element={<CommonDataModel />}
              ></Route>
              {/* <Route
                exact
                path="/factorybuilder"
                element={
                  myAdminRecord && myAdminRecord.admin ? (
                    <FactoryBuilder />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route> */}
              <Route
                exact
                path="/create"
                element={user ? <Create /> : <Navigate to="/" replace={true} />}
              ></Route>
              <Route
                exact
                path="/factorycreate"
                element={
                  user ? <FactoryCreate isEdit={false} /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route
                exact
                path="/factoryedit"
                element={
                  user ? <FactoryCreate isEdit={true} /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route
                exact
                path="/private-accounts"
                element={
                  myAdminRecord && myAdminRecord.admin ? (
                    <PrivateAccounts />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/uns"
                element={
                    <Uns/>
                }
              ></Route>
              <Route
                exact
                path="/flows/:flowId"
                element={
                  user ? <Flow /> : <Navigate to="/" replace={true} />
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        )}
      </Box>
      <Toast />
    </ThemeProvider>
  );
}

export default App;
