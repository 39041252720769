import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import {  Col, Flex, Row, Tree, Typography } from 'antd';
import {Grid} from '@mui/material'
import { ReactFlowProvider } from 'reactflow';
import './Uns.css'
import UnsFlow from './UnsFlow';
import { Content } from 'antd/es/layout/layout';
import { tree } from 'd3';
const { DirectoryTree } = Tree;
const {Title} = Typography;




const Uns = ({unsId}) => {
  const flowMap = {"kuDiqPpyVcemLYIzLJF7":"Life_Sciences","tHktnIRtcSYdmjTa0CZe":"General_Manufacturing"}
  const [mqttMessage, setMqttMessage] = useState({'Tulip_Experience_Center':{}});
  const [allMessages, setAllMessages] = useState({}); // New state to store all messages by topic
  const [isConnected, setIsConnected] = useState(false);
  const [antTree, setAntTree] = useState([]);
  const [hotTopic, setHotTopic] = useState(); // For tracking blinking keys
  const [expandedKeys, setExpandedKeys] = useState([]); // State to manage expanded keys
  const topic = `Tulip_Experience_Center/${flowMap[unsId]}/#`;


  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  // Function to update the existing message tree with new data
  const updateMessageTree = (prevMessages, newMessage) => {
    return { ...prevMessages, ...mergeDeep(prevMessages, newMessage) }; // Merge new message into the existing tree
  };
  const applyBlinkingEffect = (topicKey) => {
    // Find all elements that have a `data-topic` attribute
    const allElements = document.querySelectorAll('[data-topic]');
    // Iterate over the list of elements to find the one with the matching topic
    allElements.forEach((element) => {
      if (element.dataset.topic.toLowerCase() === topicKey.toLowerCase()) {
        element.classList.add('blinking'); // Add the blinking class
        setHotTopic(topicKey.toLowerCase())
  
        // Remove the class after a delay
        setTimeout(() => {
          element.classList.remove('blinking');
          setHotTopic(null)
        }, 500); // Duration of the blink animation
      }
    });
  };
  
  const handleTreeClick = (keys, info) => {
    console.log('Trigger Select', keys, info);
  };
  const convertToTreeData = (obj, parentKey = '0', keyCounter = { current: 0 }) => {
    // Initialize an array to hold the tree nodes
    const treeNodes = [];
  
    // Iterate over each key in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const nodeKey = `${parentKey}-${keyCounter.current}`; // Generate unique key
        const nodeTitle = key; // Format the title by replacing underscores and converting to uppercase
        const children = obj[key];
        
        const treeNode = {
            title: nodeTitle,
            key: nodeKey,
            dataTopic: children._topic , // Add data-topic to the tree node if _topic exists
          };
  
        // Check if the node has the _topic attribute
        if (children && children._topic) {
          treeNode['data-topic'] = children._topic; // Set the data-topic attribute if _topic exists
        }
       
        // Check if children have a Value key
        if (children && children.Value !== undefined && children.Value !== null) {

        treeNode.title = (
            <span>
            <span >{nodeTitle}: </span>
            <span className="node-value">{children.Value.toString()}</span>
            </span>
        )
          treeNode.isLeaf = true; // Mark as leaf if it contains Value
          treeNodes.push(treeNode);
        } else if (typeof children === 'object' && children !== null && !isEmptyObject(children) ) {
        treeNodes.push(treeNode);
          treeNode.children = convertToTreeData(children, nodeKey, keyCounter); // Recursively convert children
        } else {
          treeNode.isLeaf = true; // Mark as leaf if no children
        }
  
        // Add the current node to the tree
      
     
        
        keyCounter.current++; // Increment the key counter for the next node
      }
    }
  
    return treeNodes; // Return the constructed tree nodes
  };
  

  // Recursive function to merge two objects deeply
  const mergeDeep = (target = {}, source = {}) => {
    // Loop through each key in the source object
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        // If the source value is an object and the target has that key
        if (source[key] instanceof Object && key in target) {
          // Recursively merge the objects
          target[key] = mergeDeep(target[key], source[key]);
        } else {
          // Otherwise, set or replace the value in the target
          target[key] = source[key];
        }
      }
    }
    return target; // Return the updated target
  };
  
  const structureMessageByTopic = (topic, msg) => {
    const levels = topic.split('/'); // Split topic into levels
    const nestedMessage = levels.reduceRight((acc, level) => {
      if (acc && typeof acc === 'object' && Object.keys(acc).length !== 0) {
        // If it's the last level (the one containing actual data), add the topic
        if (levels[levels.length - 1] === level) {
          acc['_topic'] = topic; // Set the topic only at the final level
        }
      }

      return { [level]: acc }; // Build the hierarchy
    }, msg);
  
    return nestedMessage; // Return the nested message
  };
  

  useEffect(() => {
    // Connect to the HiveMQ broker
    const client = mqtt.connect('wss://a2417032a8f1427685fda167966172a5.s2.eu.hivemq.cloud:8884/mqtt', {
      username: 'hivemq.webclient.1693222052513',
      password: '7P&TLW3Qf.k0am5d*V#t', 
      rejectUnauthorized: false, // Set to false for self-signed certs (not recommended for production)
    });
    setMqttMessage({});
    setAntTree([]);
    // On connection
    client.on('connect', () => {
      //console.log('Connected to MQTT broker');
      setIsConnected(true);
      
      // Subscribe to a topic
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Subscribed to topic: ${topic}`);
        } else {
          console.error('Subscription error: ', err);
        }
      });
    });
    client.on('message', (topic, message) => {
        let msg = {};
        try {
            msg = { data: JSON.parse(message.toString()), topic: topic };
        } catch (error) {
            console.log(topic, message)
            console.error("Failed to parse JSON:", error, "Message:", message.toString());
            return;
        }
        const tree = structureMessageByTopic(topic, msg.data);
        
        // Normalize topic to lowercase
        const topicKey = topic; 
        if (topic.toLocaleLowerCase().includes('test_stand')) {
            console.log(msg);
        }
        const newValue = msg.data.Value; // Get the new value from the message
        
        // Update allMessages state
        setAllMessages(prev => {
          const oldMessage = prev[topicKey]; // Get the previous message for comparison
      
          // Check if this topic already exists in oldMessage
          if (!oldMessage) {
            //console.log('new message entirely');
            console.log(mqttMessage)
             applyBlinkingEffect(topicKey); 
            // If there's no previous message, it's a new topic, so we add it to blinkingKeysy
          } else if (oldMessage.Value !== newValue) {
            //console.log(oldMessage.Value, newValue, topicKey)
            applyBlinkingEffect(topicKey); 
          }
          // Now, update the message for this topic
          const updatedMessages = { ...prev, [topicKey]: msg.data }; // Update to latest message
          // Set a timer to clear the blinking key after a short duration Adjust the time as needed
      
          return updatedMessages; // Return the updated messages state
        });
        
        // Update mqttMessage state
        setMqttMessage(prevMessages => updateMessageTree(prevMessages, tree));
        
        // Convert the new tree directly from the merged result
        const newAntTree = convertToTreeData(updateMessageTree(mqttMessage, tree));
        setAntTree(newAntTree);
        
        // Set all keys as expanded
        const allKeys = [];
        const flattenKeys = (nodes) => {
          nodes.forEach(node => {
            allKeys.push(node.key);
            if (node.children) {
              flattenKeys(node.children);
            }
          });
        };
        flattenKeys(newAntTree);
        setExpandedKeys(allKeys);
      });
      
      
      

    // Handle connection errors
    client.on('error', (err) => {
      console.error('Connection error: ', err);
    });

    // Cleanup connection on unmount
    return () => {
      client.end();
    };
  }, [unsId]); // Add mqttMessage as a dependency to rerun effect when it updates


  return (
        <Row justify="space-between" className='antContainer' >
        <Col
        span={6} // Fixed size for tree column
        className={'unsFlex'}
        >
            <Flex 
            vertical>
            <Title level={5} style={{color:'#F2EEA1', marginTop:'5px'}}>Unified Name Space</Title>
                
                <Content className='customScroll' >
                    <div style={{flex:"1 1 auto", maxHeight:"100%"}}>
                    <DirectoryTree 
                        treeData={antTree} 
                        expandedKeys={expandedKeys} 
                        showLine={true}
                        showIcon={false}
                        className={'tree'}
                        selectable={false}
                        //onSelect={handleTreeClick}
                    />
                    </div>
                 </Content>
        </Flex>
        </Col>
      <Col
      flex="1"
      >

    <ReactFlowProvider>
        <UnsFlow hotTopic={hotTopic} flowId={unsId}/>

    </ReactFlowProvider>
      </Col>
        </Row>
  );
};

export default Uns;
